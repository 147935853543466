import React, { Component } from "react";
import TextField from "../components/form/textField.js";
import TextArea from "../components/form/textArea.js";
import api from "../api";
import FacebookPixel from "../lib/tracking/facebook-pixel";

export default class ContactForm extends Component {
  constructor (props) {
    super(props);
    this.state = {
      // form data
      email: "",
      name: "",
      phone: "",
      company: "",
      resellerType: "",
      message: "",

      // UI
      submitting: false,
      sent: false
    };
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = async e => {
    e.preventDefault();
    try {
      this.setState({ submitting: true });

      let formId;
      let data;
      if (this.props.isWholesale) {
        formId = process.env.GATSBY_CONTACT_FORM_ID_WHOLESALE;
        data = {
          "your-email": this.state.email,
          "your-phone": this.state.phone,
          "your-company": this.state.company,
          "your-name": this.state.name,
          "your-reseller-type": this.state.resellerType,
          "your-message": this.state.message
        };
      } else {
        formId = process.env.GATSBY_CONTACT_FORM_ID_CONTACT_US;
        data = {
          "your-email": this.state.email,
          "your-name": this.state.name,
          "your-message": this.state.message
        };
      }

      await api.contactForms.feedback(formId, data);

      FacebookPixel.track("Contact");
      FacebookPixel.track("Lead");

      this.setState({ submitting: false, sent: true });
    } catch (e) {
      throw e;
    } finally {
      this.setState({ submitting: false });
    }
  };

  render () {

    // before sent
    if (!this.state.sent) {
      const isWholesale = this.props.isWholesale;
      return (
        <form onSubmit={this.onSubmit} className="form">

          <TextField label="Email Address" name="email" type="email" value={this.state.email} onChange={this.handleChange} />
          {isWholesale &&
          <TextField label="Phone Number" name="phone" type="tel" value={this.state.phone} onChange={this.handleChange} />
          }
          {isWholesale &&
          <TextField label="Company Name" name="company" value={this.state.company} onChange={this.handleChange} />
          }
          <TextField label="Full Name" name="name" value={this.state.name} onChange={this.handleChange} />
          {isWholesale &&
          <div className="field">
            <div className="select">
              <select name="resellerType" onChange={this.handleChange} required>
                <option value="">What type of reseller</option>
                <option>Independent Pharmacy</option>
                <option>Medical Office / Wellness Center</option>
                <option>Natural Foods / Product Store</option>
                <option>Convenience / Grocery Store</option>
                <option>Head Shop / Smoke Shop / Dispensary</option>
                <option>CBD Store</option>
                <option>Salon / Spa / Health Club</option>
                <option>Private Label Inquiry</option>
                <option>Other</option>
              </select>
            </div>
          </div>
          }
          <TextArea label="Message" name="message" value={this.state.message} onChange={this.handleChange} />

          <div className="field">
            <button type="submit"
              className={`button is-primary is-uppercase is-fullwidth${this.state.submitting ? " is-loading" : ""}`}
              disabled={this.state.submitting}>
              SEND MESSAGE
            </button>
          </div>
        </form>
      );
    }


    // after sent
    return (
      <div className="success-feedback">
        <img src={require("../images/done.svg")} alt="" className="success-feedback--check" />
        <h3>Your message successfully sent.</h3>
        <p>Stay in touch we will contact you.</p>
      </div>
    );

  }
}
