import React from "react";
import TextField from "./textField";

export default class TextArea extends TextField {

  render () {
    const { label, name, value } = this.props;

    const fieldClass = ["field"];
    if (value) fieldClass.push("has-value");
    if (this.state.focused) fieldClass.push("focused");

    return (
      <div className={fieldClass.join(" ")}>
        {label && <label className="label floating-label">{label}</label>}
        <textarea
          required
          className="textarea"
          name={name}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          value={value}
        />
      </div>
    );
  }

}
