import React from "react"
import { Link } from "gatsby";

export const commonFAQs = [
  {
    title:<h3>What is CBD?</h3>,
    content: <p>CBD or Cannabidiol is a phytocannabinoid that interacts with the body’s CB1 and CB2 receptors which make up our endocannabinoid system. Smile is 100% organic CBD isolate to ensure you’re getting the most out of your cannabidiol experience.</p>
  },
  {
    title:<h3>What other ingredients are in your CBD products?</h3>, content: <p>All ingredients are available on our site! Visit the <Link to="/ingredients">link</Link> here to checkout the active ingredients on all of our CBD products. We are a transparency first company! </p>
  },
  {
    title:<h3>Does CBD get me high?</h3>,
    content: <p>CBD is in no part psychoactive nor does it distort or impair cognitive functionality and judgment. In short, no you will not get high from using CBD! </p>
  },
  {
    title:<h3>How should I store CBD?</h3>,
    content: <p>For best practice, be sure to store any CBD product in a dark area to ensure no loss in overall potency. With a 1-year shelf life, your Smile CBD will never go to waste!</p>
  },{
    title:<h3>Will I pass a drug test if I use CBD?</h3>,
    content: <p>If this is of concern, please consult your physician! As with all CBD/ Hemp products brought to market, there is potential to have complications with drug tests. That being said, CBD is a 100% Legal, hemp derived cannabinoid made federally legal in 2018 through the passing of the Farm Bill. </p>
  },{
    title:<h3>How do I use CBD?</h3>,
    content: <p><u>CBD Oil:</u><br/>Hold a dose under the tongue for 30-60 seconds (Kickstart in the AM or when you need an energy boost, Unwind before bed or when you want to chill out)<br/><br/>Additionally, many people don’t realize that CBD Oil has many amazing use cases/ scenarios, so that you can sprinkle CBD into any morning or nightly routine. For instance, drop a dose into your morning coffee for some extra energy with Kickstart. On the other hand you can drop a dose of Unwind into a nightly tea for some extra peace of mind before bed. CBD Oil is profoundly versatile!<br/><br/><u>CBD Gummies:</u><br/>Take one Smile CBD gummy, daily! Similar to a multivitamin, our CBD gummies are crafted with a host of vitamins and should be treated as a once-daily regiment, if possible!</p>
  }
]

export const shippingFAQs = [
  {
    title:<h3>When should I expect my CBD? </h3>,
    content: <p>The typical time for any Reason To Smile product to show up at your door should be within 1 to 3 business days! On special occasions during times of high volume orders, processing and shipments can potentially be delayed by a number of days. Take this into consideration when allowing for additional days in transit for delivery. </p>
  },
  {
    title:<h3>How can I track my order? </h3>, content: <p>Upon purchase, you will receive email confirmation outlining your shipment details! If you did not receive this email, please check All Mail or any associated subsection of your email address as messages sometimes are delivered outside of Inbox.<br/><br/>If you have reviewed your email thoroughly and still cannot find your shipment details, please contact us at <a href="mailto:hello@reasontosmile.com">hello@reasontosmile.com</a> and we will provide you with your tracking number.  </p>
  },
  {
    title:<h3>How often should I reorder? </h3>,
    content: <p>We suggest that our customers renew their order with us when their CBD is at about half to ensure consistent use without delay between orders! This ensures you won’t run out of CBD! </p>
  },
  {
    title:<h3>What if my product is damaged? </h3>,
    content: <p>We are not liable for any products damaged or lost during shipping. In an instance where you have received your order and the product is damaged, please contact the shipment carrier to file a claim.<br/><br/>If you file a claim with the shipment carrier, we require you save all packaging material and damaged goods before, during, and after the filing.</p>
  }
]

export const returnsFAQs = [
  {
    title:<h3>Can I return a product?</h3>,
    content: <p>We strive to provide you with products that are the perfect fit for your health & lifestyle. We hope you’ll be completely satisfied with your products and will accept any full-sized products for a return (no exchanges), as long as it is at least half full and received within 30 days of initial purchase. If the item has been unreasonably tampered with, the original contents are not returned at least half-full, or shows signs of excessive wear and tear from user misuse, we reserve the right to deny a return<br/><br/>Prior to returning any item, we require that the customer must let us know that a return is on it’s way by contacting us at <a href="mailto:hello@reasontosmile.com">hello@reasontosmile.com</a>. This is to ensure our staff is aware of an incoming item in a case where we don’t receive your package.<br/><br/>When returning by mail, you will be responsible for covering shipping costs to return items and shipping charges must be prepaid. Depending on your location, the shipping method and carrier used to return, your return package may take several days or weeks to reach us. Keep your proof of postage and ship your return along with a tracking number as we are not liable for return packages that are lost or stolen in-transit. Without proof of receipt and delivery, Reason to Smile will not issue a refund for your returns. Once your return is fully processed, we will issue your refund via the same manner of payment received. If you are shipping an item over $50, you should consider purchasing shipping insurance. Reason to Smile cannot guarantee that we will receive your returned item.<br/><br/>To return your product, please mail your product to:<br/><br/>Reason to Smile Corp<br/><br/>1440 S. State College Blvd, Suite 5F <br/><br/>Anaheim, CA 92806 </p>
  },
  {
    title:<h3>What are the requirements to return my product?</h3>, content: <p>As long as it is at least half full and received within 30 days of initial purchase.<br/><br/>Prior to returning any item, we require that the customer must let us know that a return is on it’s way by contacting us at <a href="mailto:hello@reasontosmile.com">hello@reasontosmile.com</a><br/><br/>We ask that our customers be responsible for covering shipping costs to return items and shipping charges must be prepaid<br/><br/>Without proof of receipt and delivery, Reason to Smile will not issue a refund for your returns.</p>
  },
  {
    title:<h3>Can I receive a refund? </h3>,
    content: <p>Yes! If all requirements for proper return are appropriately met, and your return is fully processed, we will issue your refund via the same manner of payment received within 30 days.</p>
  },
  {
    title:<h3>When can I cancel an order? </h3>,
    content: <p>We will cancel an order only prior to order processing. This process typically begins as soon as possible if not immediately but may take up to 24-48 hours. However, should you wish to change or cancel your order, you are welcome to send an email to <a href="mailto:hello@reasontosmile.com">hello@reasontosmile.com</a> to check whether the processing of an order has commenced, and if it has not, we may be able to accommodate your request to cancel and will do our best to meet your needs. <br/><br/>If the order has not been shipped out yet, and we’re able to cancel it, we will provide a full refund to the original form of payment used to place the order. Accordingly, we encourage you to carefully check your order before submitting it.</p>
  }
]
