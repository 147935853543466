import React, {Component} from "react";
import { isBrowser } from "react-device-detect";
import Layout from "../components/layout";
import Accordion from "../components/accordion";
import Highlightable from "../components/highlightable";
import {commonFAQs, returnsFAQs, shippingFAQs} from "../lib/faq-content";
import AnimatedLines from "../components/animatedLines";
import ContactForm from "../components/contactForm";
import SEO from "../components/seo"
import "./faq.scss";

class FaqPage extends Component {
  
  componentDidMount() {

  }
  render() {

    return (
      <Layout isAlternate={true}>
        <SEO title="Frequently Asked Questions" description="What is CBD? How do I use CBD oil? Learn about our CBD products and read our frequently asked questions at ReasonToSmile.com" />  
      <section className="hero has-yellow-background has-absolute-navbar">
      <div className="hero-body">

        <div className="container">
        <div className="columns is-centered is-vcentered">
        <div className="column is-relative happiness-inside-image" style={{
          backgroundImage: `url(https://reasontosmile.wpengine.com/wp-content/uploads/2020/01/WebsiteFinals-11.jpg)`
        }}/>
          <div className="column is-6 has-text-centered has-text-white">
            <h1>Questions? Comments? Concerns?</h1>
            <h4>Our team of dedicated experts is here for you.</h4>
          </div>

        </div>

          </div>
        </div>

      </section>

      <section className="section">

        <div className="container">
          <div className="columns is-centered">
            <div className="column is-4">
              <h2>General</h2>
            </div>
            <div className="column is-8">
              <Accordion data={commonFAQs}/>
            </div>
          </div>
          <div className="columns is-centered">
            <div className="column is-4">
              <h2>Shipping</h2>
            </div>
            <div className="column is-8">
              <Accordion data={shippingFAQs}/>
            </div>
          </div>
          <div className="columns is-centered">
            <div className="column is-4">
              <h2>Returns and Refunds</h2>
            </div>
            <div className="column is-8">
              <Accordion data={returnsFAQs}/>
            </div>
          </div>
        </div>

      </section>
      <section id="reach-out-section" className="section is-relative has-white-background">
      <div className="section-bg">
          <AnimatedLines className="lines-02" style={{
            transform: "rotate(0deg)",
            position: "absolute",
            top: isBrowser ? "5%" : "-10%",
            right: isBrowser ? "-18%" : "-130%",
            zIndex: "1",
            width: "654px"
          }} />
        </div>
      <div className="container">
        <div className="columns">
          <div className="column is-3">
            <h3 className="has-text-weight-bold">
              <Highlightable>Still seeking answers?</Highlightable>
            </h3>
            <h1>Go ahead, reach out</h1>
            <h4>Don’t hesitate to contact us for whatever reason. Drop us a message & we’ll get back to you shortly.</h4>

           
          </div>
          <div className="column is-8 is-offset-1">
              <div id="contact-form-container">

                <ContactForm/>
                </div>
          </div>
          
        </div>
        
      </div>
    </section>
      </Layout>
    )
  }
}

export default FaqPage
